import { FFmpeg } from '@ffmpeg/ffmpeg'; 
import { fetchFile, toBlobURL } from '@ffmpeg/util'; 

const ffmpeg = new FFmpeg();
let isFFmpegLoaded = false;
let isFFmpegInitialized = false;
let logCount = 0;

const DOMElements = {
  imageInput: document.getElementById('imageInput'),
  audioInput: document.getElementById('audioInput'),
  createButton: document.getElementById('createButton'),
  previewButton: document.getElementById('previewButton'),
  video: document.getElementById('video'),
  downloadLink: document.getElementById('downloadLink'),
  log: document.getElementById('log'),
  progressBar: document.getElementById('progressBar'),
  progress: document.getElementById('progress')
};

let resizedImages = [];
let resizedImageDimensions = [];

const readAndResizeImageFiles = async (imageFiles) => {
  return await Promise.all(imageFiles.map(async (file) => {
    const imageBitmap = await createImageBitmap(file);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const { newWidth, newHeight } = calculateDimensions(imageBitmap);
    resizeImage(canvas, ctx, imageBitmap, newWidth, newHeight);

    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    resizedImageDimensions.push({ width: newWidth, height: newHeight });
    return new Uint8Array(await blob.arrayBuffer());
  }));
};

const calculateDimensions = (imageBitmap) => {
  const ratio = imageBitmap.width / imageBitmap.height;
  let newWidth = imageBitmap.width;
  let newHeight = imageBitmap.height;

  if (newWidth > 1200) {
    newWidth = 1200;
    newHeight = Math.round(newWidth / ratio);
  } else if (newHeight > 1200) {
    newHeight = 1200;
    newWidth = Math.round(newHeight * ratio);
  }

  // Ensure width and height are even numbers
  if (newWidth % 2 !== 0) newWidth -= 1;
  if (newHeight % 2 !== 0) newHeight -= 1;

  return { newWidth, newHeight };
};

const resizeImage = (canvas, ctx, imageBitmap, newWidth, newHeight) => {
  canvas.width = newWidth;
  canvas.height = newHeight;
  ctx.drawImage(imageBitmap, 0, 0, newWidth, newHeight);
};

const updateLog = (msg, append = true) => {
  logCount++;
  if (append) {
    DOMElements.log.innerHTML += `${logCount}: ${msg}\n`;
  } else {
    DOMElements.log.innerHTML = `${logCount}: ${msg}`;
  }
  DOMElements.log.scrollTop = DOMElements.log.scrollHeight;
  console.log(`${logCount}: ${msg}`);
};

const updateProgressBar = (percent) => {
  DOMElements.progress.style.width = `${percent}%`;
  DOMElements.progressBar.setAttribute('aria-valuenow', percent);
};

const initializeFFmpeg = async () => {
  if (isFFmpegInitialized) {
    updateLog('FFmpeg is already initialized.');
    return;
  }
  isFFmpegInitialized = true;

  updateLog('Loading FFmpeg core...');
  const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';

  ffmpeg.on('log', ({ message: msg }) => {
    updateLog(msg, true);
    const frameMatch = msg.match(/frame=\s*(\d+)/);
    if (frameMatch) {
      const currentFrame = parseInt(frameMatch[1], 10);
      const totalFrames = parseInt(DOMElements.progressBar.getAttribute('data-total-frames'), 10);
      const percent = Math.round((currentFrame / totalFrames) * 100);
      updateProgressBar(percent);
    }
  });

  if (!isFFmpegLoaded) {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm')
    });
    isFFmpegLoaded = true;
  }

  updateLog('FFmpeg core loaded successfully.');

  DOMElements.imageInput.addEventListener('change', async () => {
    const imageFiles = Array.from(DOMElements.imageInput.files);
    if (imageFiles.length > 0) {
      updateLog('Resizing images...');
      resizedImages = await readAndResizeImageFiles(imageFiles);
      updateLog('Images resized successfully.');
    }
  });

  DOMElements.createButton.addEventListener('click', () => createVideo(false));
  DOMElements.previewButton.addEventListener('click', () => createVideo(true));
};

const getAudioDuration = (file) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(URL.createObjectURL(file));
    audio.addEventListener('loadedmetadata', () => {
      resolve(Math.floor(audio.duration));
    });
    audio.addEventListener('error', () => {
      reject(new Error('Failed to load audio metadata.'));
    });
  });
};

const createVideo = async (isPreview) => {
  const audioFile = DOMElements.audioInput.files[0];

  if (resizedImages.length === 0 || !audioFile) {
    updateLog('Please upload both images and an audio file.');
    return;
  }

  try {
    updateLog('Reading audio file...');
    const audioData = await fetchFile(audioFile);

    const { width, height } = resizedImageDimensions[0]; // Assuming all resized images have the same dimensions

    for (let i = 0; i < resizedImages.length; i++) {
      await ffmpeg.writeFile(`input${i}.jpg`, resizedImages[i]);
    }
    await ffmpeg.writeFile('input.mp3', audioData);

    const duration = isPreview ? 5 : parseInt(await getAudioDuration(audioFile));
    if (isNaN(duration)) {
      updateLog('Error: Could not determine audio duration.');
      return;
    }

    const totalFrames = isPreview ? 5 * 25 : duration * 25; // Assuming 25 fps
    DOMElements.progressBar.setAttribute('data-total-frames', totalFrames);
    updateLog(`Total frames to process: ${totalFrames}`);

    const imageDisplayTime = Math.max(1, Math.min(3, Math.floor(duration / resizedImages.length)));

    const ffmpegCommand = buildFFmpegCommand(resizedImages, imageDisplayTime, totalFrames, width, height, isPreview, duration);

    updateLog(`Final FFmpeg command: ${ffmpegCommand.join(' ')}`);
    await ffmpeg.exec(ffmpegCommand);

    const output = isPreview ? 'output_preview.mp4' : 'output.mp4';
    const data = await ffmpeg.readFile(output);

    updateLog('Creating video URL...');
    const videoURL = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
    displayVideo(videoURL, output, isPreview);
  } catch (error) {
    updateLog(`Error: ${error.message}`, true);
  } finally {
    updateProgressBar(100);
  }
};

const buildFFmpegCommand = (imageDataList, imageDisplayTime, totalFrames, width, height, isPreview, duration) => {
  let command = [];
  for (let i = 0; i < imageDataList.length; i++) {
    command.push('-loop', '1', '-t', imageDisplayTime.toString(), '-i', `input${i}.jpg`);
  }

  const filterComplex = imageDataList.map((_, i) => `[${i}:v]scale=${width}:${height},setsar=1[v${i}]`).join(';') +
    `;` +
    imageDataList.map((_, i) => `[v${i}]`).join('') +
    `concat=n=${imageDataList.length}:v=1:a=0,format=yuv420p,loop=loop=-1:size=${totalFrames}[v]`;

  command = [
    ...command,
    '-i', 'input.mp3',
    '-filter_complex', filterComplex,
    '-map', '[v]', '-map', `${imageDataList.length}:a`,
    '-shortest'
  ];

  if (isPreview) {
    command.push('-t', '5');
  } else {
    command.push('-t', duration.toString());
  }

  command.push(isPreview ? 'output_preview.mp4' : 'output.mp4');
  return command;
};

const displayVideo = (videoURL, output, isPreview) => {
  DOMElements.video.src = videoURL;
  DOMElements.video.classList.remove('hidden');
  DOMElements.downloadLink.href = videoURL;
  DOMElements.downloadLink.download = output;
  DOMElements.downloadLink.classList.remove('hidden');
  DOMElements.downloadLink.textContent = isPreview ? 'Download Preview Video' : 'Download Video';
  updateLog('Video created successfully.');
};

document.addEventListener('DOMContentLoaded', initializeFFmpeg, { once: true });
